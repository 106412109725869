import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const PaymentPage = lazy(() => import('../modules/payment/WizardsPage'))
    const PaymentConfirmPage = lazy(() => import('../modules/profile2/ProfilePage'))
    const PaymentCancelPage = lazy(() => import('../modules/profile3/ProfilePage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const Documents = lazy(() => import('../modules/apps/files/FilesPage'))
    const DeletePetition = lazy(() => import('../modules/apps/user-management/users-list/delete-petition/DeletePetition'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    const OfficePage = lazy(() => import('../modules/apps/offices/OfficePage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/crafted/pages/profile/overview' />} />
                {/* Pages */}
                <Route path='dashboard' element={<ProfilePage />} />
                <Route path='builder' element={<BuilderPageWrapper />} />
                <Route path='menu-test' element={<MenuTestPage />} />
                {/* Lazy Modules */}
                <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='index.html'
                    element={<Navigate to='/crafted/pages/profile/overview' />}
                />
                <Route
                    path='index'
                    element={<Navigate to='/crafted/pages/profile/overview' />}
                />
                <Route
                    path='crafted/pages/payment/confirm'
                    element={
                        <SuspensedView>
                            <PaymentConfirmPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/payment/cancel'
                    element={
                        <SuspensedView>
                            <PaymentCancelPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/payment/*'
                    element={
                        <SuspensedView>
                            <PaymentPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/crafted/files/*'
                    element={
                         <SuspensedView>
                            <Documents />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/crafted/deletion/*'
                    element={
                        <SuspensedView>
                            <DeletePetition />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/crafted/offices/*'
                    element={
                         <SuspensedView>
                            <OfficePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
