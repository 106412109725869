import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik, Field, FieldProps  } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { initialUser, User, Certificate } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { createCertificate } from '../core/_requestsCertificate'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { getApplicationTypes, getApplicationTypesList, Option } from '../../../../_requestsOptions'
import Select from 'react-select';
enum CertificateType {
    Automated = 'automated',
    Petition = 'petition',
}

type Props = {
    isUserLoading: boolean
    certificate: Certificate
}

const editUserSchema = Yup.object().shape({
  
})

const UserEditModalForm: FC<Props> = ({ certificate, isUserLoading }) => {
    const { setItemIdForUpdate } = useListView()
    const { refetch } = useQueryResponse()
    const [applicationTypes, setapplicationTypes] = useState<Array<Option> | null>(null);
    const handleFetch = async () => {
        const response = await getApplicationTypes();
        console.log(response)
        setapplicationTypes(response);
    };
    useEffect(() => {
        handleFetch();

    }, [])
    const [userForEdit] = useState<Certificate>({
        ...certificate,
        CertifId: certificate.CertifId || 0,
        AppId: certificate.AppId || 0,
        ParagrCode: certificate.ParagrCode || '',
        PrintDate: certificate.PrintDate || null,
        Notes: certificate.Notes || null,
        Cancel: certificate.Cancel || null,
        ToIs: certificate.ToIs || null,
        Id: certificate.Id || null,
        PdfName: certificate.PdfName || null,
        CertCode: certificate.CertCode || null,
        CertData: certificate.CertData || null,
        Issuer: certificate.Issuer || 0,
        CancelPrinted: certificate.CancelPrinted || null,
        AA: certificate.AA || null,
        DiaygeiaCode: certificate.DiaygeiaCode || null,
        DeciplineNote: certificate.DeciplineNote || null,
        ExactCopy: certificate.ExactCopy || null,
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }
    // Function to convert string to enum value
    function stringToEnumValue(input: string): CertificateType | undefined {
        const enumValues = Object.values(CertificateType);

        // Check if the input string is a valid enum value
        if (enumValues.includes(input as CertificateType)) {
            return input as CertificateType;
        }
        return CertificateType.Automated;

    }
    const [certificateType, setCertificateType] = useState<CertificateType | undefined>(CertificateType.Automated);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        setCertificateType(stringToEnumValue(e.target.value));
        const fieldValue = e.target.value;

    };

    const formik = useFormik({
        initialValues: userForEdit,
        onSubmit: async (values, { setSubmitting }) => {
            console.log('onSubmit');
            console.log(values);
            setSubmitting(true)
            try {
                console.log(values)
                await createCertificate(values)
            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })

    return (
        <>

            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >

                    <div className='d-flex row '>
                        <label className='d-flex mb-5 cursor-pointer col-6'>
                            <span className='d-flex align-items-center me-2'>
                                <span className='form-check form-check-custom form-check-solid m-5'>
                                    <input className='form-check-input' type='radio' name='role' value='petition' onChange={(e) => {
                                        handleChange(e);
                                    }} />
                                </span>
                                <span className='d-flex '>

                                    <span className='fw-bolder text-gray-800 text-hover-primary fs-4  mt-3'>
                                        Αίτηση για νέο Πιστοποιητικό

                                    </span>

                                </span>
                            </span>


                        </label>
                        <label className='d-flex flex-row mb-5 cursor-pointer col-6'>
                            <span className='d-flex align-items-center me-2'>
                                <span className='form-check form-check-custom form-check-solid m-5'>
                                    <input className='form-check-input' type='radio'   name='role' value='automated' onChange={handleChange} />
                                </span>
                                <span className='d-flex '>

                                    <span className='fw-bolder text-gray-800 text-hover-primary fs-4  mt-3'>
                                        1Δημιουργία Αυτόματου Πιστοποιητικού

                                    </span>

                                </span>
                            </span>


                        </label>

                       
                    </div>
                    <div className='separator mb-4'></div>
                    {certificateType == CertificateType.Petition && (<div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2 '>Η βεβαίωση θα χρησιμοποιηθεί:</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <textarea
                            placeholder=''
                          
                            name='Notes'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                { 'is-invalid': formik.touched.Notes && formik.errors.Notes },
                                {
                                    'is-valid': formik.touched.Notes && !formik.errors.Notes,
                                }
                            )}
                            autoComplete='off'
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting || isUserLoading}
                        />
                        {formik.touched.Notes && formik.errors.Notes && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.Notes}</span>
                                </div>
                            </div>
                        )}
                        <span className='required fw-italic  text-gray-800  fs-8 mb-2'>Γράφεται αναλυτικά ο λόγος και η επωνυμία της υπηρεσίας που θα κατατεθεί η ζητούμενη βεβαίωση </span>
                        <div className='separator mb-4'></div>
                        <label className='required fw-bold grey fs-6 mb-2'>Δηλώνω ότι κατέχω έμμισθη θέση στο:</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <textarea
                            placeholder=''

                            name='ToIs'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                { 'is-invalid': formik.touched.ToIs && formik.errors.ToIs },
                                {
                                    'is-valid': formik.touched.ToIs && !formik.errors.ToIs,
                                }
                            )}
                            autoComplete='off'
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting || isUserLoading}
                        />
                        {formik.touched.ToIs && formik.errors.ToIs && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.ToIs}</span>
                                </div>
                            </div>
                        )}
                        <label className='required fw-italic  fs-8 mb-2'>Πρέπει ΟΠΩΣΔΗΠΟΤΕ να συμπηρώσετε αν κατέχετε έμμισθη θέση Ιδιωτικού ή Δημόσιο Δικαίου και την επωνυμία της Υπηρεσίας διαφορετικά συμπληρώστε την λέξη OXI</label>

                        {/* end::Input */}
                    </div>)}
                    {/* begin::Input group */}
                    {certificateType == CertificateType.Automated && (<div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>Επιλέξτε από την υπάρχουσα λίστα αυτοματοποιημένων Πιστοποιητικών</label>
                        {/* end::Label */}
                        <Select
                            name="AppId"
                            options={applicationTypes || []}
                            className='form-select form-select-lg form-select-solid'
                            onChange={(selectedOption) => {
                                console.log('adsas');
                                if (selectedOption && selectedOption.value) {
                                    var test = getApplicationTypesList(selectedOption.value)
                                    console.log(test);
                                    userForEdit.AppId = parseInt(selectedOption.value, 10);
                                }
                            }}
                        />
                        {/* begin::Input */}
                      
                        <span className='fw-bolder text-gray-800 text-hover-primary fs-4-6 text-error  mt-3'>
                            Σε περίπτωση που οι τύποι πιστοποιητικών που εκδίδονται άμεσα δεν αντιστοιχεί στην Υπηρεσία που θέλετε να το καταθέσετε μεταβείτε στην "Αίτηση για έκδοση πιστοποιητικού"

                        </span>
                        {formik.touched.AppId && formik.errors.AppId && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.AppId}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>)}
                  
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                        disabled={formik.isSubmitting || isUserLoading}
                    >
                        Άκυρο
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>Υποβολή</span>
                        {(formik.isSubmitting || isUserLoading) && (
                            <span className='indicator-progress'>
                                Παρακαλώ Περιμένετε...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
        </>
    )
}

export { UserEditModalForm }
