import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik'
import { resetPassword } from '../core/_requests'  // Assuming you have a function to handle password reset requests.

const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Λάθος μορφή email')
        .min(3, 'Ελάχιστο 3 σύμβολα')
        .max(50, 'Μέγιστο 50 σύμβολα')
        .required('Το email είναι υποχρεωτικό'),
    password: Yup.string()
        .min(6, 'Ελάχιστο 6 σύμβολα')
        .required('Ο νέος κωδικός είναι υποχρεωτικός'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Οι κωδικοί δεν ταιριάζουν')
        .required('Η επιβεβαίωση κωδικού είναι υποχρεωτική'),
});

export function ResetPassword() {
    const [code, setCode] = useState("");
    const location = useLocation(); // Assuming the code is passed via URL parameters
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    console.log("code" + code)
    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {

                resetPassword(values.email, values.password, values.password, code) // Adjust this call based on your API requirements
                    .then(({ data: { result } }) => {
                        setHasErrors(result)
                        setLoading(false)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Τα στοιχεία που εισάγατε είναι λανθασμένα. Παρακαλώ επικοινωνήστε με τον Σύλλογο.')
                    })
            }, 1000)
        },
    })
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const resetCode = params.get('code');
        if (resetCode) {
            setCode(resetCode);
            console.log(resetCode); // Check if the code is correctly set
        }
       
    }, [location]);
    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Επαναφορά Κωδικού Πρόσβασης</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Συμπληρώστε τα παρακάτω πεδία για να επαναφέρετε τον κωδικό σας.
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Error Messages */}
            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        Κάτι πήγε στραβά παρακαλώ δοκιμάστε ξανά.
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Η επαναφορά κωδικού ολοκληρώθηκε με επιτυχία.</div>
                </div>
            )}
            {/* end::Error Messages */}

            {/* begin::Email Field */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        { 'is-valid': formik.touched.email && !formik.errors.email }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Email Field */}

            {/* begin::Password Field */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Νέος Κωδικός</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.password && formik.errors.password },
                        { 'is-valid': formik.touched.password && !formik.errors.password }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Password Field */}

            {/* begin::Confirm Password Field */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Επιβεβαίωση Κωδικού</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirmPassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                        { 'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword }
                    )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Confirm Password Field */}

            {/* begin::Submit Button */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Υποβολή</span>
                    {loading && (
                        <span className='indicator-progress'>
                            Παρακαλώ περιμένετε...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Άκυρο
                    </button>
                </Link>
            </div>
            {/* end::Submit Button */}
        </form>
    )
}
